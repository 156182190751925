@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.PaymentMethodNav {
  display: flex;
  justify-content: center;

  .nav-link {
    border-radius: 200px !important;
    border: 2px solid #f1f1f1;
    padding: 6px 14px;
    padding-bottom: 9px;

    &.active {
      background: transparent !important;
      border-color: #007bff;
    }

    &:not(.active):hover {
      border-color: #ddd;
    }
  }
}

.planCard {
  border-radius: 4px;
  border: 2px solid #f1f1f1;
  cursor: pointer;

  &:not(.selected):hover {
    border-color: #ccc;
  }

  &.selected {
    border: 2px solid #007bff;
  }
}

@include color-mode(dark) {
  .nav-link {
    border-color: $border-color-dark;
    &.active {
      border-color: #055bb6;
    }
    &:not(.active):hover {
      border-color: $border-color-translucent-dark;
    }
  }

  .planCard {
    border-color: $border-color-dark;
    &:not(.selected):hover {
      border-color: $border-color-translucent-dark;
    }
    &.selected {
      border-color: #055bb6;
    }
  }
}
