@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

html:not(.tw-dark) {
  .Logs {
    .custom-switch {
      .custom-control-input {
        &:checked ~ .custom-control-label#blocked-queries-only {
          &::before {
            border-color: #ff4136;
            background-color: #ff4136;
          }
        }
      }
    }

    .tracker-icon {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  .tooltip-device {
    .tooltip-inner {
      max-width: 600px;
    }
  }

  .log {
    .reason-icon {
      display: none;
    }

    &:hover {
      .domain.has-tracker-insights {
        display: 'inline-block';
        padding-bottom: 1px;
        border-bottom: 1px dotted rgba(black, 0.3);
        cursor: pointer;
      }

      .reason-icon {
        display: inline-block;
      }
    }

    .log-type-badge {
      font-weight: 600px;
      font-size: 10px;
      opacity: 0.4;
      margin-left: 8px;
      background: #f1f1f1;
      padding: 1px 4px;
    }  
  }

  .fadein {
    animation: fadein 0.8s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .stream-button {
    opacity: 0.5;
    width: 24px;
    height: 24px;
    padding: 4px;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }

    &.streaming {
      animation: rotate 2s infinite linear;
      opacity: 1;
      svg {
        fill: #007bff;
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .settings-button {
    opacity: 0.5;
    width: 24px;
    height: 24px;
    padding: 4px;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }

    &.active {
      opacity: 1;
      svg {
        fill: #007bff;
      }
    }
  }

  .no-caret::after {
    display: none;
  }
}

@include color-mode(dark) {
  .settings-button, .stream-button {
    fill: $light;
  }

  .Logs {
    .settings-button.active, .stream-button.streaming {
      svg {
        fill: #007bff !important;
      }
    }
  }

  svg.fa-circle-xmark path {
    fill: white !important;
  }

  .log {
    .log-type-badge {
      background: #1b1b1b !important;
    }
  }
}
