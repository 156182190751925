
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$recreation-color-light: #b7cc00;
$recreation-color-dark: #92a204;

.recreation-button {
  opacity: 0.15;
  width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;

  @media (hover: hover) {
    &:not(.readOnly):hover {
      opacity: 0.3;
    }
  }

  &.active {
    opacity: 1;
    svg {
      fill: $recreation-color-light;
    }
  }

  &.readOnly {
    cursor: default;
  }
}

.recreation-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  opacity: 0.5;
  margin-top: -3px;
  vertical-align: middle;
}

.btn.btn-recreation {
  @include button-variant($recreation-color-light, lighten($recreation-color-light, 5%));
  color: white !important;
}

@include color-mode(dark) {
  .btn.recreation-button {
    fill: white;
    &.active svg {
      fill: $recreation-color-dark;
    }
  }  

  .btn-recreation {
    @include button-variant($recreation-color-dark, lighten($recreation-color-dark, 5%));
  }
}
