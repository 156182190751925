@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource/inter/variable.css';
@import '@fontsource/roboto-mono/variable.css';

$spacer: 1rem !default;
$card-spacer-y: $spacer * 1.4;
$card-spacer-x: $spacer * 1.35;
$list-group-item-padding-y: $spacer * .75;
$list-group-item-padding-x: $spacer;

// Remove xxl breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@import '~bootstrap/scss/bootstrap.scss';

input[type='search'] {
  -webkit-appearance: none;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  &::-webkit-search-results-button {
    -webkit-appearance: none;
  }
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

// REACT_APP_TAILWIND=1
html.tw {
  height: -webkit-fill-available;

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;

    @apply tw-min-h-screen tw-bg-white tw-font-sans;
  }

  &.tw-dark {
    body {
      @apply tw-bg-slate-900;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  ::placeholder {
    opacity: 0.5 !important;
  }

  .container {
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (min-width: 576px) and (max-width: 1199px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

$bg-2-light: #fcfcfc;
$bg-2-dark: #1b1e21;
::root {
  --bg-2: $bg-2-light;
}
.bg-2 {
  background-color: $bg-2-light;
}

.injected-svg {
  fill: currentColor;
}

@include color-mode(dark) {
  --bg-2: $bg-2-dark;

  .bg-2 {
    background-color: $bg-2-dark;
  }  

  .tooltip {
    --bs-tooltip-bg: #{$body-bg-dark};
    --bs-tooltip-color: #{$body-color-dark};
  }

  $button-dark: #1c1f22;
  button.btn.btn-light:not(#fake-id) {
      @include button-variant(
      $button-dark,
      $button-dark,
      $hover-background: shade-color($button-dark, $btn-hover-bg-shade-amount),
      $hover-border: shade-color($button-dark, $btn-hover-border-shade-amount),
      $active-background: shade-color($button-dark, $btn-active-bg-shade-amount),
      $active-border: shade-color($button-dark, $btn-active-border-shade-amount)
    )
  }

  .fa-xmark, .fa-times, .fa-close {
    color: #919294;
  }  
}

html:not(.tw) {
  &[lang^='zh'],
  &[lang^='ja'],
  &[lang^='ko'] {
    body,
    .popover,
    .tooltip {
      font-family: sans-serif;
    }
  }

  height: -webkit-fill-available;

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    display: flex;
    flex-direction: column;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  ::placeholder {
    opacity: 0.5 !important;
  }

  .container {
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (min-width: 576px) and (max-width: 1199px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  label {
    font-weight: 500;
    font-size: 0.95rem;
    margin-bottom: 0.3rem;
  }

  h5 {
    line-height: 1.3em;
  }

  .progress {
    height: 8px;
  }

  .invalid-feedback {
    display: block;
  }

  .search-highlight {
    background: var(--bs-body-color);
    color: var(--bs-body-bg);
    border-radius: 1px;
  }
}

.beta-badge {
  background: orange;
  border-radius: 3px;
  padding: 1px 3px;
  margin-top: -7px;
  margin-left: 8px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}

@include color-mode(dark) {
  .beta-badge {
    background: #be6a02;
  }
}

.access-badge {
  background: #aaa;
  border-radius: 3px;
  padding: 1px 3px;
  margin-left: 10px;
  margin-right: 5px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}

@include color-mode(dark) {
  .access-badge {
    background: #485058;
  }
}

.no-scrollbars {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  /* Chrome, Safari, Opera */
  &::-webkit-scrollbar {
      display: none;
  }
}

// Restore bootstrap v4 button variant=link behavior
.btn-link {
  text-decoration: none !important;
}
.btn-link:hover {
  text-decoration: underline !important;
}
.btn-link:hover, a {
  text-decoration-thickness: 0.05em;
  text-underline-offset: 0.1em;
}
