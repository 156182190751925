@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.HeaderMessage {
	background: #007bff;
	color: white;

  &.near-quota {
    background: #ff851b;
  }
  &.over-quota {
    background: #ff4136;
  }
}

.Header {
	background: #fefefe;
	border-bottom: 1px solid #dee2e6;
	padding-top: 16px;
}


/* TODO: cleanup both this and the className in the html */
html {
  &[lang^="ja"] {
    .Header {
      .nav-link.menu {
        font-size: 0.95em;
      }
    }
  }
}

@include color-mode(dark) {
  .HeaderMessage {
    background: #0056b3;
    color: #e0e0e0;

    &.near-quota {
      background: #cc7000;
    }
    &.over-quota {
      background: #c92020;
    }  
  }

  .Header {
    background: #1c1f22;
    border-bottom-color: #474b51;

    button {
      border-color: $border-color-dark;
    }
  }
}