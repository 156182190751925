@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Analytics {
	.progress.dnssec {
		.progress-bar {
			background-color: #4085c2;
		}
	}

	--area-queries-stroke: #98caff;
	--area-queries-fill: #f3f9ff;
	--area-queries-blocked-stroke: #ff4136;
	--area-queries-blocked-fill: #ff4136;

	.map-tooltip {
		opacity: 0.98 !important;
		padding: 0.8rem 0.9rem !important;
		font-size: 1em !important;
		border-radius: 5px !important;
		background: var(--bs-body-bg) !important;
		box-shadow: 0px 0px 8px rgba(0,0,0,0.1) !important;
		color: var(--bs-text) !important;
	}

	.map-tooltip.place-top {
		&:after {
			background: var(--bs-body-bg) !important;
			border-top-color: var(--bs-body-bg) !important;
		}
	}

	.map-tooltip.place-bottom {
		&:after {
			background: var(--bs-body-bg) !important;
			border-bottom-color: var(--bs-body-bg) !important;
		}
	}

	.map-tooltip.place-left {
		&:after {
			background: var(--bs-body-bg) !important;
			border-left-color: var(--bs-body-bg) !important;
		}
	}

	.map-tooltip.place-right {
		&:after {
			background: var(--bs-body-bg) !important;
			border-right-color: var(--bs-body-bg) !important;
		}
	}
}

@include color-mode(dark) {
	.Analytics {
		--area-queries-stroke: #448acf;
		--area-queries-fill: #243343;
		--area-queries-blocked-stroke: #ff4136;
		--area-queries-blocked-fill: #ff4136;

		.map-tooltip {
			box-shadow: 0px 0px 8px rgba(0,0,0,0.5) !important;
		}
	}
}