@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Setup {
  .endpoint-name {
    @media (max-width: 575px) {
      font-size: 0.9em;
      font-weight: 500;
    }
  }

  .code-block {
    background: #fbfbfb;
    border-radius: 5px;
    overflow-x: auto;
    padding: 12px 15px;

    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    font-size: 13px;
    line-height: 20px;
    color: #222;
    white-space: nowrap;
  }
}

@include color-mode(dark) {
  .Setup {
    .code-block {
      background: #1b1e21;
      color: #fbfbfb
    }
  }
}